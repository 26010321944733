<script>
import Emoji from "@/Emoji.vue";
import it from "element-ui/src/locale/lang/it";
import Avatar from "@/Avatar.vue";

export default {

  components: {Avatar, Emoji},
  props: {
    sourceType: {
      type: String,
      require: true
    },

    photoId: {
      //评论所属文章 id
      type: String
    },
    emptyText: {
      // 评论为空的时候显示的文字
      type: String,
      default: "Looking forward to your comments！"
    },
    buttonText: {
      // 按钮文字
      type: String,
      default: "评论"
    },
    contentLength: {
      // 评论长度
      type: Number,
      default: 200
    }
  },
  data() {
    return {
      textarea: {
        minRows: 3,
        maxRows: 6
      },

      placeholderText: "Please enter a comment of up to 200 words...",
      userId: null,
      role: null,
      profileUrl: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
      bigLoading: false,
      pantPageNum: "1", // 父级查看更多
      params: {
        pageNum: "1",
        pageSize: "5",
        orderByColumn: "createTime",
        isAsc: "asc",
      },
      total: 0,
      replyTotal: 0,
      comments: [
        {
          id: '',
          content: '',
          userId: '',
          level: '',
          createTime: '',
          uname: '',
          avatar: require(`./assets/profile/1.png`),
          auditStatus: "0", // 审核状态
          commentReplyCount: 1, // 二级回复条数
          commentReplyInfoList: [], // 二级回复数组
          pageNum: 1,
          pageSize: 3,
          replyTotal: 0,
          morel: false
        }
      ], // 获取得到的评论
      replyInfo: null,
      context: "", // 评论内容
      commentId: "",
      receive: {
        userId: '',
        uname: ''
      },
      replyShow: false,
      morelist: {
        index: '2',
        value: false,
        loading: false,
      },
      childrenMorelist: {
        index: '2',
        childrenId: "",
        childrenPageNum: "1", // 子级查看更多
        childrenTotal: 0,
        value: false,
        loading: false,
      },

      dateDialogVisible: false,
      dateTime: '',
      modifyContent: '',
      modifyId: ''
    };
  },

  async mounted() {
    await this.getCommentList();
    this.userId = parseInt(sessionStorage.getItem("userId"));
    this.role = sessionStorage.getItem("role")
    if (this.isLogin) {
      this.placeholderText = 'log on first please!'
    }
  },

  computed: {
    it() {
      return it
    },
    isLogin() {
      return this.$store.state.token === '';
    },
  },
  methods: {
    chooseEmoji(layer, val, item) {
      this.context += val;
    },
    modifyChooseEmoji(layer, val, item) {
      this.modifyContent += val;
    },
    // 查看更多评论
    async changeMore() {
      this.morelist.loading = true;
      this.pantPageNum++;
      this.params.pageNum = this.pantPageNum;
      let res = await this.getNewList();
      let comment = res.data.records;
      comment.forEach(x => {
        this.setAvatar(x)
        this.getReplyList(x)
      })
      this.comments.push(...comment);
      this.morelist.loading = false;
      this.total = res.data.total;
      if (res.data.total > this.params.pageNum * this.params.pageSize) {
        this.morelist.value = true;
      } else {
        this.morelist.value = false;
      }
    },
    // 加载更多
    async changeChildrenMore(item, index) {
      item.pageNum++;
      this.childrenMorelist.loading = true;
      this.childrenMorelist.index = index;
      await this.getReplyList(item);
      this.childrenMorelist.loading = false;
      this.scrollToBottom()
    },

    async getReplyList(item) {
      if (!item.commentReplyInfoList) {
        item.commentReplyInfoList = [];
      }
      if (!item.pageNum && !item.pageSize) {
        item.pageNum = 1;
        item.pageSize = 3;
      }
      let res = await this.$http.get(`/api/photo/replyComment?id=${item.id}&pageNum=${item.pageNum}&pageSize=${item.pageSize}`)
      item.commentReplyInfoList.push(...res.data.records);
      item.commentReplyInfoList.forEach(x => {
        this.setAvatar(x)
      })
      item.replyTotal = res.data.total;
      item.morel = item.replyTotal > item.pageNum * item.pageSize;
      this.comments.push();
    },

    // 获取本篇文章所有评论
    async getCommentList() {
      try {
        this.comments = [];
        this.bigLoading = true;
        // 获取某篇文章下的所有评论
        const res = await this.$http.get(`/api/photo/comments?pageNum=${this.params.pageNum}&pageSize=${this.params.pageSize}&photoId=${this.photoId}`)
        res.data.records.forEach(x => {
          this.setAvatar(x)
          this.getReplyList(x)
        })
        this.bigLoading = false;
        this.comments = res.data.records; //评论列表
        this.total = res.data.total; //评论总数
        if (this.total > this.params.pageSize) {
          this.morelist.value = true;
        } else {
          this.morelist.value = false;
        }
      } catch (err) {
        this.bigLoading = false;
        this.$message.error(err);
      }
    },

    async getNewList() {
      return this.$http.get(`/api/photo/comments?pageNum=${this.params.pageNum}&pageSize=${this.params.pageSize}&photoId=${this.photoId}`);
    },

    setAvatar(item) {
      if (item.avatar) {
        item.avatar = {}
      }
      item.avatar = require(`./assets/profile/${item.avatarCode}.png`)
    },

    // 控制二级条数显示盒子
    hanlePublicTotal(item) {
      // item.publicTotal = !item.publicTotal;
      // console.log("item", item);
    },

    scrollToBottom() {
      this.$nextTick(() => {
        let list = document.getElementById('replyScroll');
        list.scrollTop = list.scrollHeight;
      });
    },

    isShowSecReply(item, parent) {
      if (this.isLogin) {
        this.$message.warning("log on first please!");
        return;
      }
      this.receive.uname = item.uname;
      this.receive.userId = item.userId;
      this.placeholderText = `@${item.uname}`;
      this.replyShow = true;
      this.replyInfo = item;
      if (!parent)
        this.commentId = item.id;
      else
        this.commentId = parent.id;
      let elementById = document.getElementById('commentInput');
      elementById.scrollIntoView({behavior: "smooth", block: 'center'});
      elementById.focus();
    },

    destroyReply() {
      this.replyShow = false;
      this.receive.userId = ''
      this.receive.uname = '';
      this.replyInfo = null;
      this.placeholderText = 'Please enter a comment of up to 200 words...';
    },

    // 删除
    deleteComment(item) {
      if (this.isLogin) {
        this.$message.warning("请先登录！");
        return;
      }
      if (item) {
        // 删除二级评论，提交请求到后端
        this.$confirm('Are you sure to delete this comment?', 'warn', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        }).then(() => {
          this.$http.delete(`/api/photo/comment/${item.id}`).then(async (res) => {
            if (res.status === 200) {
              this.pantPageNum = '1';
              this.params.pageNum = "1";
              await this.getCommentList();
              this.$message({
                message: "success",
                type: "success",
              });
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'canceled'
          });
        })
      }
    },
    // 评论添加成功，返回的数据
    async addComment() {
      if (this.isLogin) {
        this.$message.warning("log on first please!");
        return;
      }
      //本地更新评论列表
      if (this.replyInfo) {
        // 添加二级评论
        if (!this.context) {
          this.$message.warning("can not be empty！");
          return;
        }
        this.addSecond(this.replyInfo);
      } else {
        // 添加一级评论，提交数据到后端
        if (!this.context) {
          this.$message.warning("can not be empty！");
          return;
        }
        this.addFirst();
      }
      this.destroyReply();
      this.isShowSec = this.isClickId = "";
    },

    addSecond(item) {
      // 模拟数据提交成功后返回数据
      let data = {
        photoId: this.photoId,
        commentId: this.commentId,
        recipientId: item.userId,
        level: 2,
        content: this.context //评论内容
      };

      this.$http.post("/api/photo/comment", data).then(async (res) => {
        if (res.status === 200) {
          await this.getReplyList(item);
          this.context = "";
        }
      })
    },
    addFirst() {
      // 模拟数据提交成功后返回数据
      let data = {
        photoId: this.photoId,
        level: 1,
        commentId: null,
        content: this.context //评论内容
      };
      this.$http.post("/api/photo/comment", data).then(async (res) => {
        if (res.status === 200) {
          let res = await this.getNewList();
          this.comments = res.data.records;
          this.context = "";
          this.total = res.data.total;
          if (res.total > 5) {
            this.morelist.value = true;
          }
        }
      })
    },

    openDatePicker(item) {
      this.modifyId = item.id
      this.modifyContent = item.content
      this.dateTime = item.createTime
      this.dateDialogVisible = true;
    },

    confirmDate() {
      this.$http.put('/api/photo/comment', {
        'id': this.modifyId,
        'content': this.modifyContent,
        'dateTime': this.dateTime
      }).then(res => {
        if (!res.data) {
          this.$message.error('Failed to modify, please try again later!');
        }
      })
      this.dateDialogVisible = false;
      this.$router.go(0);
    },

    cancelModify() {
      this.modifyId = ''
      this.modifyContent = ''
      this.dateTime = ''
      this.dateDialogVisible = false;
    }

  }
};

</script>

<template>
  <div class="comment">
    <div class="flex_box">
      <h2 style="color: #dbdadf">comments ({{ total }})</h2>
      <!--            <p>Civilized Internet rational speech</p>-->
    </div>
    <div class="comment-header">
      <h4 v-if="this.replyShow"
          style="display: inline-block; padding: .5rem; margin-bottom: 10px; background-color: rgb(116, 162, 228); border-radius: 3px; font-size: .875rem; line-height: 1; color: gainsboro">
        reply to {{ receive.userId === userId ? 'me' : receive.uname }}
        <small>
          <a @click="destroyReply()" style="cursor: pointer; text-decoration: underline; margin-left: .5rem;">Cancel
            reply</a>
        </small>
      </h4>
      <div class="header_children">
        <el-input
            id="commentInput"
            :disabled=this.isLogin
            :placeholder="placeholderText"
            v-model="context"
            class="input"
            type="textarea"
            resize="none"
            size="mini"
            clearable
            :maxlength="contentLength"
        ></el-input>
      </div>
      <div class="header_btn">
        <!-- 表情 -->
        <div>
          <emoji @chooseEmoji="chooseEmoji"></emoji>
        </div>
        <el-button
            type="primary"
            style="height: 40px"
            @click="addComment()"
        >{{ buttonText }}
        </el-button>
      </div>
    </div>
    <div v-loading="bigLoading">
      <div class="comment-body" v-for="(item, index) in comments" :key="item.id + '' + index">
        <!-- 一级评论 -->
        <div class="first-comment">
          <img style="cursor: pointer; margin-top: 10px; height: 55px" :src="item.avatar"/>
          <div class="content">
            <!-- 一级评论用户昵称 -->
            <div style="display: flex;align-items: center">
              <h3 style="color: #dbdadf">{{ item.uname }}</h3>
              <span v-show="item.role === 'administrator'" class="el-icon-star-off"
                    style="margin-left: 6px; background-color: rgb(9,184,132,0.2); color: rgb(12,188,135,1); font-size: 12px; border-radius: 3px; line-height: 21px">
                webmaster&nbsp;</span>
              <span v-show="item.role === 'member'" class="el-icon-user-solid"
                    style="margin-left: 6px; background-color: rgb(208,210,218,0.9); color: rgb(89,93,105,1); font-size: 12px; border-radius: 3px; line-height: 21px">
                v1&nbsp;</span>
            </div>

            <!-- 一级评论评论内容 -->
            <p style="color: gainsboro; margin-top: 10px;">{{ item.content }}</p>

            <div style="padding-top: 20px">
              <!-- 一级评论发布时间 -->
              <span style="color: gray; font-size: 12px">{{ item.createTime }}</span>
              <i
                  style="color: #dbdadf"
                  class="el-icon-chat-dot-round"
                  @click="isShowSecReply(item, undefined)"
              >reply</i>
              <i
                  v-if="userId === item.userId || role === 'administrator'"
                  class="el-icon-delete"
                  style="color: brown"
                  @click="deleteComment(item, undefined)"
              >delete</i>
              <i
                  v-if="role === 'administrator'"
                  class="el-icon-timer"
                  style="color: green"
                  @click="openDatePicker(item)"
              >modify</i>
            </div>

            <div class="li_top" v-if="item.commentReplyInfoList?.length > 0" @click="hanlePublicTotal(item)">
              <span style="color: rgb(21 66 231);">{{ item.replyTotal }} comments in total</span>
            </div>

            <ul id='replyScroll' class="infinite_list">
              <li v-for="(reply, index) in item.commentReplyInfoList" :key="reply.id + '' + index">
                <div class="second-comment">
                  <img style="cursor: pointer; margin-top: 10px; margin-right: 10px; height: 50px"
                       :src="reply.avatar"/>
                  <div>
                    <div style="display: flex;align-items: center;">
                      <h4 style="color: #dbdadf; margin-bottom: 5px">{{
                          reply.userId === userId ? 'Me' : reply.uname
                        }}</h4>
                      <span v-show="reply.role === 'administrator'" class="el-icon-star-off"
                            style="margin-left: 6px; background-color: rgb(9,184,132,0.2); color: rgb(12,188,135,1); font-size: 12px; border-radius: 3px; line-height: 21px">
                webmaster&nbsp;</span>
                      <span v-show="reply.role === 'member'" class="el-icon-user-solid"
                            style="margin-left: 6px; background-color: rgb(208,210,218,0.9); color: rgb(89,93,105,1); font-size: 12px; border-radius: 3px; line-height: 21px">
                v1&nbsp;</span>
                    </div>
                    <!-- 次级评论评论时间 -->
                    <span style="color: gray;">{{ reply.createTime }}</span>
                    <span v-show="userId !== reply.userId"
                          class="to_reply">&nbsp;&nbsp;&nbsp;{{ reply.userId === userId ? 'I' : reply.uname }}</span>
                    <span v-show="userId !== reply.userId" style="color: #8c939d">&nbsp;reply&nbsp;</span>
                    <span v-show="userId !== reply.userId" class="to_reply">{{ reply.recipientName }}</span>:
                    <p style="color: gainsboro; margin-top: 10px; margin-bottom: 5px">{{ reply.content }}</p>
                    <div class="comment-right">
                      <i
                          v-if="reply.userId !== userId"
                          class="el-icon-chat-dot-round"
                          @click="isShowSecReply(reply, item)"
                          style="color: gainsboro"
                      >reply</i>
                      <i
                          v-if="reply.userId === userId || role === 'administrator'"
                          class="el-icon-delete"
                          style="color: brown;"
                          @click="deleteComment(reply)"
                      >delete</i>
                      <i
                          v-if="role === 'administrator'"
                          class="el-icon-timer"
                          style="color: green"
                          @click="openDatePicker(reply)"
                      >modify</i>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <el-button class="loading_p" type="text" size="mini"
                       :loading="childrenMorelist.loading && index === childrenMorelist.index"
                       v-if="item.morel"
                       @click="changeChildrenMore(item, index)">more...
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        title="Modify"
        :visible.sync="dateDialogVisible"
        :modal-append-to-body="false"
        width="30%"
        center>
      <div style="text-align: center">
        <span>modify time</span>
        <div style="margin-bottom: 20px">
          <el-date-picker
              v-model="dateTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm"
              placeholder="Select date time"
              default-time="12:00:00">
          </el-date-picker>
        </div>
        <span>modify comment</span>
        <div>
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              :maxlength="200"
              v-model="modifyContent">
          </el-input>
          <div class="emojiDiv">
            <emoji @chooseEmoji="modifyChooseEmoji"></emoji>
          </div>
        </div>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button @click="cancelModify">cancel</el-button>
        <el-button type="primary" @click="confirmDate">confirm</el-button>
      </div>
    </el-dialog>
    <!-- 一级查看更多 -->
    <div style="display: flex; justify-content: center">
      <el-button type="text" class="moreBtn" :loading="morelist.loading" @click="changeMore" v-if="morelist.value">
        Load More
        <i class="el-icon-caret-bottom"></i>
      </el-button>
    </div>
    <!-- <div v-else class="more_div">暂无更多评论</div> -->
    <!-- 暂无评论的空状态 -->
    <el-empty :description="emptyText" v-if="comments.length === 0"></el-empty>
  </div>
</template>

<style lang="scss" scoped>
.comment {
  margin-bottom: 10%;
  min-height: 26vh;
  border-radius: 5px;
  margin-top: 2px;
  overflow: hidden;

  h3 {
    margin: 5px 0;
  }

  p {
    margin: 3px 0;
  }

  ul {
    list-style-type: none;
  }

  .img_width {
    max-width: 50px;
    max-height: 50px;
    border-radius: 50px;
    font-size: 30px;
    color: #3339;
  }

  .flex_box {
    padding: 0 5px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      margin: 0;
    }

    p {
      font-size: 16px;
      color: #666;
      margin: 0 0 0 20px;
    }
  }

  .active {
    color: rgb(202, 4, 4);
  }

  .comment-header {
    position: relative;
    //height: 50px;
    padding: 10px 5px;
    // display: flex;
    // align-items: center;
    .header_children {
      display: flex;
      align-items: center;
    }

    .header_btn {
      display: flex;
      //align-items: center;
      justify-content: space-between;
      //padding: 0 10px 0 60px;
      margin-top: 10px;
    }

    .input {
      //margin-left: 10px;
      //margin-right: 20px;
      flex: 1;
      font-size: 14px;

      ::v-deep .el-input__inner:focus {
        border-color: #dcdfe6;
      }
    ;

      ::v-deep(.el-textarea__inner) {
        //border: none;
        color: gainsboro;
        background-color: #151B2A;
        height: 100px;
      }
    }
  }

  .comment-body {
    font-size: 14px;

    .first-comment {
      display: flex;
      padding: 10px 20px;

      .input {
        ::v-deep.el-input__inner:focus {
          border-color: #dcdfe6;
        }
      }

      i {
        margin-right: 5px;
        margin-left: 1vw;
        cursor: pointer;

        // &:nth-child(3) {
        //   color: rgb(202, 4, 4);
        // }
      }

      .content {
        margin-left: 10px;
        position: relative;
        flex: 1;

        & > span {
          font-size: 12px;
          color: rgb(130, 129, 129);
        }

        .comment-right {
          //position: absolute;
          //right: 0;
          //top: 0;
        }

        .reply-comment {

          .input {
            ::v-deep(.el-input__inner) {
              color: gainsboro;
              background-color: #151B2A;
            }
          }

          ::v-deep(.el-textarea__inner) {
            color: gainsboro;
            background-color: #151B2A;
          }

          ::v-deep(.el-textarea .el-input__count) {
            background-color: rgba(255, 255, 255, 0.0);
          }

          // height: 60px;
          // display: flex;
          // align-items: center;
          .flex_one_box {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
          }

          .reply-button {
            color: #fde2e2;
            margin-left: 20px;
            height: 35px;
          }
        }

        .li_top {
          width: 200px;
          cursor: pointer;
          padding-left: 50px;
        }

        .infinite_list {
          //max-height: 333px;
        }

        .second-comment {
          display: flex;
          padding: 10px 0 10px 5px;
          border-radius: 20px;
          background: #151B2A;

          .to_reply {
            // color: rgb(126, 127, 128);
            color: #0d74e1;
          }
        }

        .loading_p {
          cursor: pointer;
          margin: 3px auto;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .moreBtn {
    //border: none;
    //background-color: #1a2234;
    //width: 100%;

    i {
      font-size: 16px;
    }
  }

  .more_div {
    display: flex;
    justify-content: center;
    color: #66666694;
    font-size: 14px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: #F5F5F5;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #c0c0c0;
    border-radius: 10px;
    background-color: #f9f9f9;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #c0c0c0;
    background-color: #c0c0c0;
  }
}

::v-deep(.el-dialog) {
  background-color: #1a2234;
}

::v-deep(.el-dialog__title) {
  color: silver;
}
</style>