<script>
export default {
  name: "sign.vue",
  data() {

    const validateUsername = async (rule, value, callback) => {
      if (value === '') {
        callback(new Error('please enter login id'));
      }else if (value.length < 8 || value.length > 16){
        callback(new Error('length is 8 to 16 characters'));
      }else if (!this.validUsername(value)){
        callback(new Error('must contain letters and numbers'));
      }else if (await this.checkLoginId()){
        console.log("???")
        callback(new Error('account has already been used'));
      }else {
        callback();
      }
    };

    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('please enter password'));
      }else if (value.length < 8 || value.length > 16){
        callback(new Error('length is 8 to 16 characters'));
      }else if (!this.validPassword(value)){
        callback(new Error('must contain upper case, lower case, and numbers'));
      }else {
        if (this.ruleForm.confirmPass !== '') {
          this.$refs.ruleForm.validateField('confirmPass');
        }
        callback();
      }
    };

    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('please enter your password again'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('entered passwords differ!'));
      } else {
        callback();
      }
    };

    const validateCaptcha = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('please enter captcha'));
      } else {
        if (value.toLowerCase() !== this.captcha.toLowerCase()) {
          callback(new Error('captcha verification failed!'));
        }
        callback();
      }
    };

    return {
      ruleForm: {
        username: '',
        password: '',
        confirmPass: '',
        captcha: ''
      },

      rules: {
        username: [
          {required: true, validator: validateUsername, trigger: 'blur'},
        ],
        password: [
          {required: true, validator: validatePass, trigger: 'blur'}
        ],
        confirmPass: [
          {required: true, validator: validatePass2, trigger: 'blur'}
        ],
        captcha: [
          {required: true, validator: validateCaptcha, trigger: 'blur'}
        ]
      },

      labelPosition: 'right',
      captchaImg: '',
      captcha: ''
    }
  },

  created() {
    this.changeCaptcha()
  },

  destroyed() {
    if (this.ruleForm.captcha && URL.createObjectURL) {
      URL.createObjectURL(this.ruleForm.captcha)
    }
  },

  methods: {
    async changeCaptcha() {
      const response = await this.$http.get("/api/captcha", {responseType: 'blob'});
      this.captchaImg = URL.createObjectURL(new Blob([response.data], {type: 'image/png'}))
      this.captcha = response.headers.get('captcha')
    },

    /**
     * @param value
     * 测试密码是否满足条件，包括3种类型
     */
    validPassword(value) {
      const num = /^.*[0-9]+.*/
      const low = /^.*[a-z]+.*/
      const up = /^.*[A-Z]+.*/
      // const spe = /^.*[^a-zA-Z0-9]+.*/
      return num.test(value) && low.test(value) && up.test(value)
    },


    validUsername(value) {
      const num = /^.*[0-9]+.*/
      const low = /^.*[a-z]+.*/
      const up = /^.*[A-Z]+.*/
      // const spe = /^.*[^a-zA-Z0-9]+.*/
      return num.test(value) && (low.test(value) || up.test(value))
    },

    async checkLoginId(){
      const response = await this.$http.get(`api/checkLoginId?loginId=${this.ruleForm.username}`);
      console.log(response.data)
      return response.data;
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post(`/api/signup`, {
            username: this.ruleForm.username,
            password: this.ruleForm.password
          })
              .then(response => {
                if (response.data === true){
                  this.$emit('close')
                  this.$notify({
                    title: 'successful',
                    message: 'Congratulations, your account has been registered',
                    type: 'success',
                    duration: 0
                  });
                }else {
                  this.$notify.error({
                    title: 'failed',
                    message: 'Registration failed, please try again later'
                  });
                }
              })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<template>
  <div>
    <el-form :label-position="labelPosition" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="113px"
             style="margin-top: 20px">
      <el-form-item label="Login ID:" prop="username">
        <el-input v-model="ruleForm.username" placeholder="LoginID"></el-input>
      </el-form-item>
      <el-form-item label="Password:" prop="password">
        <el-input v-model="ruleForm.password" placeholder="Password"
                  show-password></el-input>
      </el-form-item>
      <el-form-item label="Confirm Pass:" prop="confirmPass">
        <el-input v-model="ruleForm.confirmPass" placeholder="ConfirmPass"
                  show-password></el-input>
      </el-form-item>
      <el-form-item label="CAPTCHA: " prop="captcha">
        <div style="display: flex">
          <el-input type="text" v-model="ruleForm.captcha" placeholder="Captcha"
                    style="width: 60%; margin-right: 15px">
          </el-input>
          <img :src="captchaImg" alt="captcha" @click="changeCaptcha" style="cursor: pointer"/>
        </div>
      </el-form-item>

    </el-form>

    <div slot="footer" class="dialog-footer" style="display: flex; justify-content: center; margin-top: 70px">
      <el-button type="primary" @click="submitForm('ruleForm')" style="width: 40%;">Submit</el-button>
    </div>

  </div>
</template>

<style scoped lang="less">

::v-deep(.el-form-item__label) {
  color: white;
}

</style>