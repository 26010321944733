import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import router from './router'
import store from './store'
import axios from 'axios'

import mixin from './mixin'

// 完整引入 Element
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })
Vue.mixin(mixin)
Vue.config.productionTip = false

Vue.prototype.$setPageTitle = function (title) {
    document.title = title;
};

Vue.prototype.$http = axios;

const request = axios.create({
    headers: {'Content-Type': 'application/json;charset=UTF-8'}
});

axios.interceptors.request.use(
    function (config) {
        const token = store.state.token
        // console.log(config)
        //  判断请求时是否携带着token,当有token,则给请求头添加token
        if (token) {
            config.headers.Authorization = token
        }
        // 直接返回的是res.data中的数据
        return config
    }, function (error) {
        // 对响应错误做点什么
        return Promise.reject(error)
    })

axios.interceptors.response.use(response => {

        return response;

    }, error => {

        const status = error.response.status;
        if (status === 401) {
            store.commit('clear')
            ElementUI.MessageBox.confirm('User authentication has expired. Please log in again！', 'Error', {
                confirmButtonText: 'confirm',
                cancelButtonText: 'cancel',
                showCancelButton: false,
                type: 'error',
                center: true
            }).then(() => {
                window.location.reload();
            })
        }else {
            const errorMsg = error.response.data.errorMsg;
            ElementUI.MessageBox.confirm(errorMsg, 'Error',{
                confirmButtonText: 'confirm',
                showCancelButton: false,
                type: 'error',
                center: true
            })
        }
        // return Promise.reject(error)
    }
)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

