<script>

import login from "@/login.vue";
import sign from "@/sign.vue";
import store from "@/store";
import Avatar from "@/Avatar.vue";
import router from "@/router";

export default {
  name: "navigationBar",
  components: {Avatar, login, sign},

  data() {
    return {
      userId: '',
      uname: '',
      role: '',
      avatarDialogVisible: false,
      dialogVisible: false,
      signDialogVisible: false,
      commentDrawer: false,
      circleUrl: require('./assets/profile/1.png'),
      centerDialogVisible: false,
      valUrl: '',
      image: null,
      title: '',
      description: '',
      textarea: {
        minRows: 4,
        maxRows: 8
      },
      home: 'http://' + window.location.hostname,
      replyList: [],
      replyTotal: 0,
      loading: false,
      pageNum: 1,
      pageSize: 10
    }
  },

  mounted() {
    this.userId = this.$store.state.userId;
    this.uname = this.$store.state.uname;
    this.role = this.$store.state.role;
    this.circleUrl = require(`./assets/profile/${this.$store.state.avatarCode}.png`)
  },

  methods: {
    login() {
      this.dialogVisible = true
    },
    close() {
      this.dialogVisible = false
    },
    sign() {
      this.signDialogVisible = true
    },
    signClose() {
      this.signDialogVisible = false
    },

    openAvatar() {
      this.avatarDialogVisible = true
    },

    closeAvatar() {
      this.avatarDialogVisible = false
    },

    openCommentDrawer() {
      this.$http.get(`/api/photo/reply?pageNum=${this.pageNum}&pageSize=${this.pageSize}&recipientId=${this.userId}`)
          .then(res => {
            if (res.status === 200){
              this.replyTotal = res.data.total
            }
          })
      this.commentDrawer = true;
    },

    closeCommentDrawer() {
      this.commentDrawer = false;
    },

    open() {
      this.$confirm('This operation will exit the login. Do you want to continue?', 'warning', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        store.commit('clear');
        window.location.reload()
      })
    },

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    handleFileChange(e) {
      const file = e.target.files[0];

      if (!file.type.match("image.*")) {
        this.$notify.error('只支持上传(jpg,jpeg,png)类型的图片')
        this.$refs.fileInput.value = null
        return;
      }

      if (file.size > 1024 * 1024 * 10) {
        this.$notify.error('图片大小不能超过10M')
        this.$refs.fileInput.value = null
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.valUrl = e.target.result;
      }

      reader.readAsDataURL(file);

      this.image = file;
    },

    async onSubmit() {
      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      const formData = new FormData();
      formData.append('file', this.image)
      formData.append('title', this.title)
      formData.append('desc', this.description)
      const response = await this.$http.post('/api/photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })


      if (response.data) {
        this.closeFrom()
        loading.close()
        this.$router.go(0)
        // window.location.reload();
      }
    },

    changeAvatar(val) {
      this.circleUrl = require(`./assets/profile/${val}.png`)
    },

    closeFrom() {
      this.centerDialogVisible = false;
      this.valUrl = '';
      this.title = '';
      this.description = ''
    },

    clearSessionStorage() {
      sessionStorage.setItem('page', '')
      sessionStorage.setItem('size', '')
    },

    openEditProfile() {
      this.$prompt('Change user nickname', '', {
        inputPattern: /^.{0,10}$/,
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        inputErrorMessage: 'The name cannot exceed 10 characters'
      }).then(async ({value}) => {
        const response = await this.$http.post('/api/user', {
          'userId': this.userId,
          'uname': value
        })
        if (response.status === 200) {
          this.$store.commit('setUname', value)
          this.uname = value
          this.$message({
            type: 'success',
            message: 'success'
          });
        }
      });
    },

    load () {
      this.$http.get(`/api/photo/reply?pageNum=${this.pageNum}&pageSize=${this.pageSize}&recipientId=${this.userId}`).then(res => {
        if (res.status === 200){
          if (this.replyList){
            this.replyList = []
          }
          res.data.records.forEach(x => {
            this.setAvatar(x)
          })
          this.replyList = res.data.records;
        }
      })
      this.pageSize += 1;
    },

    setAvatar(item){
      if (item.avatar){
        item.avatar = {}
      }
      item.avatar = require(`./assets/profile/${item.avatarCode}.png`)
    },

    toInfo(id) {
      router.push(`/info?id=${id}`)
      this.$router.go(0)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }

  }
}
</script>
<template>
  <div>
    <div style="z-index: 999;height: 6%; position: fixed; background-color: #1d273b; width: 100%; display: flex; justify-content: space-between; align-items: center;
  border-bottom: 1px solid #243049">
      <img src="./assets/logo.jpg" style="height: 35px; margin-left: 10%; border-radius: 8px" alt="logo"/>

      <div style="display: inline-flex; margin-right: 45%">
        <a :href="this.home" class="hover-link" @click="clearSessionStorage">Home</a>
      </div>

      <el-dialog
          :visible.sync="centerDialogVisible"
          :modal-append-to-body="false"
          top="5%"
          width="40%"
          center>
        <div style="margin-left: 5%;">
          <el-button style="width: 200px; height: 200px" class="el-icon-plus avatar-uploader-icon"
                     v-if="!this.valUrl" @click="openFileDialog"></el-button>
          <img :src="this.valUrl" style="width: 60%; cursor: pointer" v-if="this.valUrl" @click="openFileDialog"/>
          <input type="file" style="display: none" accept="image/jpeg,image/png,image/jpg" ref="fileInput"
                 @change="handleFileChange">
        </div>
        <div style="margin-left: 5%">
          <el-form label-position="top">
            <el-form-item label="title" style="width: 60%">
              <el-input maxlength=200 show-word-limit placeholder="Please enter your title" v-model="title"></el-input>
            </el-form-item>
            <el-form-item label="description" style="width: 70%">
              <el-input
                  type="textarea"
                  :autosize="textarea"
                  maxlength=10000
                  show-word-limit
                  placeholder="Please enter your content"
                  v-model="description">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
                    <el-button @click="closeFrom">cancel</el-button>
    <el-button type="primary" @click="onSubmit">confirm</el-button>

  </span>
      </el-dialog>

      <div style="padding-right: 20%; width: 7%">
        <div style="display: flex" v-if="$store.state.token">
          <i class="el-icon-upload" style="margin-top: 10px" @click="centerDialogVisible = true"
             v-if="this.$store.state.role === 'administrator'"></i>
          <el-tooltip effect="light">
            <img style="height: 45px ;cursor: pointer" :src="circleUrl"/>
            <div slot="content">
              <div style="display: flex">
                <div @click="openAvatar()">
                  <img style="height: 50px; cursor: pointer" :src="circleUrl"/>
                </div>
                <div style="margin-left: 5px">
                      <span v-show="this.role === 'administrator'" class="el-icon-star-off"
                            style="background-color: rgb(9,184,132,0.2); color: rgb(12,188,135,1); font-size: 12px; border-radius: 5px; line-height: 21px">
                webmaster&nbsp;</span>
                  <span v-show="this.role === 'member'" class="el-icon-user-solid"
                        style="background-color: rgb(208,210,218,0.9); color: rgb(89,93,105,1); font-size: 12px; border-radius: 5px; line-height: 21px">
                v1&nbsp;</span>
                  <span class="log_out" @click="open">
                    <i style="color: brown" class="el-icon-back"></i>
                    Logout
                  </span>
                  <h4>{{ this.uname }}</h4>
                </div>
              </div>

              <ul style="list-style: none; margin-top: 5px">
                <li class="li" @click="openEditProfile">Edit Profile</li>
                <li class="li" @click="openCommentDrawer">Message</li>
              </ul>
            </div>
          </el-tooltip>
        </div>

        <div style="display: flex">
          <el-button style="background-color: #1d273b; border-color: limegreen; color: limegreen" @click="login"
                     v-if="!$store.state.token">login
          </el-button>
          <el-button style="background-color: #1d273b; border-color: gold; color: gold" @click="sign"
                     v-if="!$store.state.token">sign up
          </el-button>
        </div>

      </div>

      <el-dialog
          title="Avatar Setting "
          :visible.sync="avatarDialogVisible"
          :modal-append-to-body="false"
          top="5%"
          width="45%"
          center>
        <Avatar @closeAvatar="closeAvatar" @elect-avatar="changeAvatar"></Avatar>
      </el-dialog>

      <el-drawer
          :visible.sync="commentDrawer"
          :modal-append-to-body="false"
          :with-header="false">
        <ul class="infinite-list"
            v-infinite-scroll="load"
            style="list-style: none; overflow: auto; height: 99vh"
        >
          <li v-for="reply in replyList" class="infinite-list-item">
            <div class="second-comment" @click="toInfo(reply.photoId)">
              <img style="cursor: pointer; margin-top: 10px; margin-right: 10px; height: 50px"
                   :src="reply.avatar"/>
              <div>
                <div style="display: flex;align-items: center;">
                  <h4 style="color: #dbdadf; margin-bottom: 5px">{{reply.uname }} reply</h4>
                </div>
                <p style="color: gainsboro; margin-top: 10px; margin-bottom: 5px">{{ reply.content }}</p>
                <span style="color: gray;">{{ reply.createTime }}</span>
              </div>
            </div>
          </li>
        </ul>
      </el-drawer>

    </div>
    <el-dialog title="Account" :visible.sync="dialogVisible" :modal-append-to-body="false" center>
      <login @close="close"></login>
    </el-dialog>

    <el-dialog title="Register Account" :visible.sync="signDialogVisible" :modal-append-to-body="false" center
               width="30%">
      <sign @close="signClose"></sign>
    </el-dialog>
  </div>
</template>
<style scoped lang="less">

.hover-link {
  color: #dbdadf;
  background-color: #1d273b;
  text-decoration: none;
  transition: color 0.3s;
}

.hover-link:hover {
  color: white;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.el-icon-upload {
  cursor: pointer;
  margin-top: 5%;
  margin-right: 15%;
  font-size: 25px;
  color: cornflowerblue;
  transition: color 0.3s;
}

.el-icon-upload:hover {
  color: #206bc4;
}

.log_out {
  cursor: pointer;
  margin-left: 70px;
  transition: color 0.2s;
}

.log_out:hover {
  color: #8c939d;
}

.li {
  font-size: 14px;
  margin-bottom: 6px;
  cursor: pointer;
  color: inherit;
  transition: color 0.2s;
}

.li:hover {
  color: #8c939d;
}

.el-form-item ::v-deep(.el-input__inner) {
  background-color: #1a2234;
  color: gainsboro;
  border: solid 2px dimgray;
  //width: 50%;
}

.el-form-item ::v-deep(.el-textarea) {
  color: gainsboro;
  border-radius: 5px;
  border: solid 2px dimgray;
  //width: 70%;
}

.el-form-item ::v-deep(.el-textarea__inner) {
  border: none;
  color: gainsboro;
  background-color: #1a2234;
}

.el-form-item ::v-deep(.el-textarea .el-input__count) {
  background-color: rgba(255, 255, 255, 0.0);
}

/deep/ .el-input .el-input__count .el-input__count-inner {
  background-color: rgba(255, 255, 255, 0.0);
}

/deep/ .el-dialog--center {
  width: 23%;
  border-radius: 10px;
  background-color: #1d273b;
  border: 1px solid #243049
}

/deep/ .el-dialog__title {
  color: white;
}

::v-deep(.el-drawer) {
  background-color: #1a2234;
}

.second-comment {
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  padding: 10px 0 10px 5px;
  border-radius: 10px;
  background: #151B2A;
}

</style>