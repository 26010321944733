<script>
import router from "@/router";
import navigationBar from "@/navigationBar.vue";

export default {
  name: "layout",
  components: {navigationBar},
  data() {
    return {
      list: [],
      currentPage: 1,
      pageSize: 24,
      total: 0,
      id: 0,
      keyword: '',
      carousel: []
    }
  },

  created() {
    if (sessionStorage.getItem('page') && sessionStorage.getItem('size')) {
      this.currentPage = Number(sessionStorage.getItem('page'))
      this.pageSize = Number(sessionStorage.getItem('size'))
    }
    this.fetchData(this.currentPage, this.pageSize);
  },

  mounted() {
    this.$setPageTitle('Pathoparadise');
  },

  methods: {
    toInfo(item) {
      router.push(`/info?id=${item.id}`)
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },

    async searchEnt() {
      await this.fetchData(this.currentPage, this.pageSize, this.keyword)
    },

    async sizeChange(value) {
      this.pageSize = value
      await this.fetchData(this.currentPage, this.pageSize)

    },

    async currentChange(value) {
      this.currentPage = value
      await this.fetchData(this.currentPage, this.pageSize)
    },

    async fetchData(currentPage, size, keyword) {
      let str = `/api/photo/page?page=${currentPage}&size=${size}`
      if (keyword) {
        str = `/api/photo/page?page=${currentPage}&size=${size}&keyword=${keyword}`
      }

      const response = await this.$http.get(str);
      this.list = response.data.records
      this.carousel = this.list.slice(0, 4)
      this.total = response.data.total
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
      sessionStorage.setItem('page', this.currentPage)
      sessionStorage.setItem('size', this.pageSize)
    }
  }
}

</script>

<template>
  <div>
    <navigationBar></navigationBar>
  <div style="background-color: #1a2234; overflow-x: hidden;">
    <div class="back">
      <div class="sub">
<!--        <el-carousel height="650px" :interval="7000">-->
<!--          <el-carousel-item v-for="(item, index) in carousel" :key="index">-->
<!--            <img class="carousel_img" :src="item.imageUrl" style="width: 100%" alt="error">-->
<!--          </el-carousel-item>-->
<!--        </el-carousel>-->
        <div style="z-index: 10; width: 400px; position: absolute; top: 45%; left: 50%; translate: -200px;">
          <el-input
              @change="searchEnt"
              style="background-color: #1a2234; border-radius: 25px"
              placeholder="please enter your search keywords"
              prefix-icon="el-icon-search"
              v-model="keyword">
          </el-input>
        </div>
      </div>
    </div>

    <div class="tag">
      <h3 style="color: gainsboro" v-if="currentPage===1">Newest</h3>
    </div>

    <div class="aaa">
      <div style="width: 24%;height: 230px; margin-right: 1%; margin-bottom: 3%; cursor: pointer;" @click="toInfo(item)"
           v-for='(item,index) in list' :key="index">
        <img :title="item.title" :src="item.imageUrl" style="width: 100%;height: 100%" :alt="item.title">
      </div>
    </div>

    <el-pagination
        style="display: flex; justify-content: center; scale: 1.2; margin: 50px"
        :current-page="currentPage"
        :page-size="pageSize"
        :page-sizes="[24, 48, 72,  108]"
        :page-count="11"
        @size-change="sizeChange"
        @current-change="currentChange"
        layout="sizes, prev, pager, next"
        :total="total">
    </el-pagination>

  </div>
  </div>
</template>

<style scoped lang="less">
.aaa {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  padding-top: 1%;
  padding-right: 10%;
  padding-left: 10%;
}

.back {
  display: flex;
  width: 100%;
  height: 100%;
  padding-top: 4%;
  background-color: #1a2234;
}

.sub {
  background-image: url(./assets/back2.png);
  background-color: #ceb275;
  background-size: cover;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;
}

@keyframes reverse {

  from{
    height: 50%;
    width: 40%;
  }
  to{
    height: 100%;
    width: 100%;
  }
}

.tag {
  display: flex;
  //height: 100px;
  margin-top: 2%;
  margin-left: 10%;
}

/deep/ .el-input--prefix .el-input__inner {
  color: white;
  border: none;
  background-color: #1a2234;
}

</style>