import axios from 'axios'

export default{
  namespaced: true,
  state(){
    return{
      list:[]
    }
  },
  mutations:{
   setbooks(state,value){
      state.list = value
   }
  },
  actions:{
   async getbooks(context){
      // console.log(context);
      const res = await axios.get('https://www.fastmock.site/mock/37d3b9f13a48d528a9339fbed1b81bd5/book/api/books')
      // console.log(res);
      context.commit('setbooks',res.data.data)
    }
  }
}