<template>
  <div style="width: 100%">
<!--    <navigationBar></navigationBar>-->
    <router-view></router-view>
  </div>
</template>

<script>
import navigationBar from "@/navigationBar.vue";
import layout from "@/layout.vue";

export default {
  components: {
    navigationBar,
    layout
  },

  data() {
    return {
    };
  },

  methods: {

  },

};
</script>


<style lang="less">

* {
  margin: 0;
}

</style>
