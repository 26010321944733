<script>
import Edit from "@/edit.vue";
import navigationBar from "@/navigationBar.vue";
import comment from "@/comment.vue";

export default {
  name: "info.vue",
  components: {navigationBar, Edit, comment},
  data() {
    return {
      textarea2: '',
      imageUrl: '',
      id: this.$route.query.id,
      title: '',
      userId: this.$store.state.userId,
      description: '',
      uploadTime: '',
      updateTime: '',
      uploader: '',
      isAdmin: this.$store.state.role === 'administrator',
      dra: false,
    }
  },

  created() {
    this.getInfo()
  },

  mounted() {
    this.$setPageTitle('Pathoparadise');
  },

  methods: {
    async getInfo() {
      const response = await this.$http.get(`/api/photo?id=${this.id}`);
      this.imageUrl = response.data.imageUrl
      this.title = response.data.title
      this.description = response.data.description
      this.uploader = response.data.uploader
      this.uploadTime = response.data.date
      this.updateTime = response.data.update
    },

    async downImage() {
      window.open(`${location.origin}/api/photo/down/${this.id}`)
    },

    openDrawer() {
      this.dra = true;
    },

    closeDrawer(v) {
      this.dra = v;
    },

    openDeleteDialog() {
      this.$confirm('This action will delete the picture, do you want to continue?', 'title', {
        confirmButtonText: 'confirm',
        cancelButtonText: 'cancel',
        type: 'warning'
      }).then(() => {
        this.deleteById();
      })
    },

    async deleteById() {
      const response = await this.$http.delete(`/api/photo/${this.id}`);
      if (response.data) {
        this.$router.go(-1);
        this.$message({
          type: 'success',
          message: 'successfully delete!'
        });
      }
    }

  }
}
</script>

<template>

  <div>
    <navigationBar></navigationBar>
    <div style="background-color: #1a2234; padding-top: 5%; height: 100%">
      <div class="aaab">
        <div class="picture_area">
          <div style="height: 100%; display: flex; justify-content: center">
            <el-image
                style="height: 100%"
                :src="imageUrl"
                :preview-src-list="[imageUrl]">
            </el-image>
          </div>


        </div>
        <div class="info" style="margin-left: 1.5%; width: 17%">
          <!--          <el-descriptions :column="1" border>-->
          <!--            <el-descriptions-item label="title">{{ title }}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="uploader">{{ uploader }}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="upload time">{{ uploadTime }}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="Latest update">{{ updateTime }}</el-descriptions-item>-->
          <!--          </el-descriptions>-->
          <el-row>
            <el-button type="primary" round icon="el-icon-download" class="down_hover-link" @click="downImage">Download
            </el-button>
          </el-row>
          <el-row>
            <el-button type="primary" round icon="el-icon-edit" class="edit_hover-link" v-if="isAdmin"
                       @click="openDrawer">Edit
            </el-button>
          </el-row>
          <edit :dra="dra" :url="imageUrl" :til="title" :desc="description" @closeDrawer="closeDrawer"></edit>
          <el-row>
            <el-button type="primary" round icon="el-icon-delete" class="del_hover-link" v-if="isAdmin"
                       @click="openDeleteDialog"/>
          </el-row>
        </div>
      </div>

      <div class="photo_desc">
        <div style="width: 1000px">
          <el-descriptions direction="vertical" :colon="false">
            <el-descriptions-item label="Description">{{ description }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="width: 18.5%"></div>
      </div>

      <div style="margin-top: 10%; height: 100%; display: flex; justify-content: center">
        <div style="width: 1000px">
          <comment :photo-id="id" :user-id="userId" :buttonText="'submit'"></comment>
        </div>
        <div style="width: 18.5%"></div>
      </div>

    </div>
  </div>

</template>

<style scoped lang="less">

.down_hover-link {
  margin-top: 15%;
  width: 80%;
  color: white;
  background-color: #2fb344;
  border: none;
  font-size: 120%;
  transition: color 0.3s;
}

.down_hover-link:hover {
  background-color: #2b9640;
}

.edit_hover-link {
  margin-top: 15%;
  width: 80%;
  color: white;
  background-color: #307fdd;
  border: none;
  font-size: 120%;
  transition: color 0.3s;
}

.edit_hover-link:hover {
  background-color: #206bc4;
}

.del_hover-link {
  margin-top: 15%;
  width: 80%;
  color: white;
  background-color: gray;
  border: none;
  font-size: 120%;
  transition: color 0.3s;
}

.del_hover-link:hover {
  background-color: dimgray;
}

.photo_desc {
  display: flex;
  justify-content: center;
  margin-top: 2%;
}

.photo_desc ::v-deep(.el-descriptions__body) {
  color: #dbdadf;
  background-color: #1a2234;
}

.photo_desc ::v-deep(.el-descriptions-item__label) {
  font-size: 15px;
  color: white;
  background-color: #1a2234;
}

.info ::v-deep(.el-descriptions__body) {
  color: white;
  background-color: #1d273b;
}

.info ::v-deep(.el-descriptions-item__label) {
  height: 70px;
  color: white;
  background: #1d273b;
}

::v-deep(.is-bordered .el-descriptions-item__cell) {
  border-color: #243049;
}

.aaab {
  display: flex;
  justify-content: center;
  height: 100%;
}

.picture_area {
  width: 1000px;
  background-color: #c7c7b2;
  border-radius: 3px;
  height: 510px;
}

</style>