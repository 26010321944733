<script>

export default {
  name: "edit",
  data() {
    return {
      drawer: false,
      title: '',
      valUrl: '',
      description: '',
      image: null,
      textarea:{
        minRows: 5,
        maxRows: 7
      }
    };
  },

  props: [
    'dra',
    'url',
    'til',
    'desc'
  ],

  watch: {
    dra: {
      handler(v) {
        this.drawer = v;
      }
    },

    til: {
      handler(v) {
        this.title = v;
      }
    },

    url: {
      handler(v) {
        this.valUrl = v;
      }
    },

    desc: {
      handler(v) {
        this.description = v
      }
    }
  },

  methods: {

    openFileDialog() {
      this.$refs.fileInput.click();
    },

    handleFileChange(e) {
      const file = e.target.files[0];

      if (!file.type.match("image.*")) {
        this.$notify.error('Only(jpg,jpeg,png)images can be uploaded')
        this.$refs.fileInput.value = null
        return;
      }

      if (file.size > 1024 * 1024 * 3) {
        this.$notify.error('The image size should not exceed 10M')
        this.$refs.fileInput.value = null
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.valUrl = e.target.result;
      }

      reader.readAsDataURL(file);

      this.image = file;
    },

    async onSubmit() {

      const loading = this.$loading({
        lock: true,
        // text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });


      const id = this.$route.query.id;
      if (!id) {
        this.$alert('An unknown error occurred. Please try again', {
          confirmButtonText: 'Confirm',
          type: "error"
        }).then(() => {
          window.location.reload();
        })
        return;
      }

      const formData = new FormData();
      formData.append('id', id)
      formData.append('file', this.image)
      formData.append('title', this.title)
      formData.append('desc', this.description)
      const response = await this.$http.post('/api/photo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      if (response.data){
        this.cancel();
        loading.close()
        this.$router.go(0)
        // window.location.reload();
      }
    },

    cancel() {
      this.drawer = false;
      this.$emit('closeDrawer', false)
    }

  }
}
</script>

<template>
  <div>
    <el-drawer
        title="edit again"
        :visible.sync=drawer
        :wrapper-closable="false"
        direction="rtl"
        size="32%"
        :before-close="cancel">
      <div class="img-icon" @click="openFileDialog">
        <img :src="valUrl"/>
        <el-button class="img-button" type="primary" icon="el-icon-upload">upload</el-button>
        <input type="file" style="display: none" accept="image/jpeg,image/png,image/jpg" ref="fileInput" @change="handleFileChange">
      </div>
      <div style="padding-top: 15%">
        <div style="padding-left: 5%">
          <el-form label-position="top">
            <el-form-item label="title" style="width: 60%">
              <el-input autosize v-model="title"></el-input>
            </el-form-item>
            <el-form-item label="description" style="width: 70%">
              <el-input
                  type="textarea"
                  :autosize="textarea"
                  maxlength=10000
                  show-word-limit
                  placeholder="Please enter your content"
                  v-model="description">
              </el-input>
            </el-form-item>
            <el-form-item style="padding-top: 30%">
              <el-button @click="cancel">cancel</el-button>
              <el-button type="primary" @click="onSubmit">confirm</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>

</template>

<style scoped lang="less">

.img-icon {
  margin-left: 5%;
  margin-right: 30%;
  margin-top: 10%;
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.img-icon img {
  display: block; /* 避免图片下方出现空隙 */
  width: 100%; /* 或者其他的宽度设置 */
}

.img-icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 半透明遮罩层 */
  opacity: 0; /* 默认情况下遮罩层不可见 */
  transition: opacity 0.3s ease; /* 添加渐变效果 */
}

.img-button {
  position: absolute;
  background-color: #2fb344;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.img-icon:hover::after {
  opacity: 1; /* 鼠标移入时遮罩层可见 */
}

.img-icon:hover .img-button {
  opacity: 1;
}


.el-form-item ::v-deep(.el-input__inner) {
  background-color: #1a2234;
  color: gainsboro;
  border: solid 2px dimgray;
  //width: 45%;
}


.el-form-item ::v-deep(.el-textarea__inner) {
  border: solid 2px dimgray;
  color: gainsboro;
  background-color: #1a2234;
}

.el-form-item ::v-deep(.el-textarea .el-input__count) {
  //color: #909399;
  //background: #1a2234
  background-color: rgba(255, 255, 255, 0.0);
}

::v-deep(.el-drawer) {
  background-color: #1a2234;
}

::v-deep(.el-form-item__label) {
  color: #ceb275;
}

</style>