<script>
import {JSEncrypt} from "jsencrypt";

export default {
  name: "login",
  data() {
    return {
      labelPosition: 'right',
      token: '',
      username: '',
      password: ''
    };
  },

  methods: {
    async login() {

      await this.$http.get("/api/publicKey")
          .then(response => {
                //获取公钥进行加密
                const publicKeyBase64 = response.data;
                const encrypt = new JSEncrypt();
                encrypt.setPublicKey(publicKeyBase64)
                const uname = encrypt.encrypt(this.username);
                const pwd = encrypt.encrypt(this.password);

                const formData = new FormData()
                formData.append('username', uname)
                formData.append('password', pwd)
                this.$http.post(`/api/login`, formData)
                    .then(response => {
                      if (response.data) {
                        this.$store.commit("setToken", response.data)
                        this.$http.get("/api/user")
                            .then(response => {
                              this.$store.commit("setUser", response.data)
                              this.username = ''
                              this.password = ''
                              this.$emit('close')
                              this.$router.go(0)
                              // window.location.reload()
                            })
                      }
                    })
              }
          )

    }
  }
}
</script>

<template>
  <div>
    <div style="margin: 20px"></div>
    <el-form :label-position="labelPosition" label-width="80px">
      <el-form-item label="Login ID:">
        <el-input v-model="username" placeholder="please enter your username"></el-input>
      </el-form-item>
      <el-form-item label="Password:">
        <el-input v-model="password" placeholder="please enter your password" @keyup.enter.native="login" show-password></el-input>
      </el-form-item>
    </el-form>

    <div slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
      <el-button type="primary" @click="login" style="width: 40%;">Login</el-button>
    </div>

  </div>
</template>

<style scoped lang="less">

::v-deep(.el-form-item__label) {
  color: white;
}

</style>