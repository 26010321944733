import Vue from 'vue'
import Vuex from 'vuex'
import books from './mudoles/books.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('token')? sessionStorage.getItem('token'):'',
    role: sessionStorage.getItem('role')? sessionStorage.getItem('role'):'',
    uname: sessionStorage.getItem('uname')? sessionStorage.getItem('uname'):'',
    userId: sessionStorage.getItem('userId')? sessionStorage.getItem('userId'):'',
    avatarCode: sessionStorage.getItem('avatarCode')? sessionStorage.getItem('avatarCode'):''
  },
  getters: {
  },
  mutations: {
    setToken(state,token){
      state.token = token;
      sessionStorage.setItem('token', token)
    },

    setUser(state, userinfo){
      state.role = userinfo.role;
      state.uname = userinfo.uname;
      state.userId = userinfo.userId;
      state.avatarCode = userinfo.avatarCode;
      sessionStorage.setItem('role', userinfo.role);
      sessionStorage.setItem('uname', userinfo.uname);
      sessionStorage.setItem('userId', userinfo.userId);
      sessionStorage.setItem('avatarCode', userinfo.avatarCode);
    },

    setUserAvatar(state, val){
      sessionStorage.setItem('avatarCode', val)
      state.avatarCode = val;
    },

    setUname(state, vla){
      sessionStorage.setItem('uname', vla)
      state.uname = vla;
    },

    clear(state){
      state.token = ''
      state.role = ''
      state.uname = ''
      sessionStorage.clear()
    }
  },
  actions: {
  },
  modules: {
    books
  }
})
