import Vue from 'vue'
import VueRouter from 'vue-router'

import main from '../main.vue'
import info from '../info.vue'
import layout from "@/layout.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/main',
    component: main,
    name: 'main',
    props: true
  },
  {
    path: '/layout',
    name: "layout",
    alias: '/home',
    props: true,
    component: layout
  },
  {
    path: '/info',
    name: 'info',
    props: true,
    component: info
  },

  {
    path: '/',
    component: layout
  }


]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
