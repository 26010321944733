<script>

export default {
  name: "Avatar",

  data() {
    return {
      avatarNum: 1,
      userId: '',
      img: require('./assets/profile/1.png')
    }
  },

  mounted() {
    const avatars = document.querySelectorAll('.avatarChoose')
    avatars.forEach(ava => {
      ava.addEventListener('mouseenter', () => {
        ava.style.position = 'relative'
        const box = document.createElement('div')
        box.classList.add('ddd')
        box.style.height = '70px'
        box.style.width = '76px'
        box.style.position = 'absolute'
        box.style.top = '10px'
        box.style.left = '2px'
        box.style.borderRadius = '50px'
        box.style.backgroundColor = 'rgba(0,0,0, 0.5)'
        ava.appendChild(box)
      })
      ava.addEventListener('mouseleave', () => {
        const dom = document.querySelector('.ddd')
        ava.removeChild(dom)
      })
    })

    this.img = require(`./assets/profile/${this.$store.state.avatarCode}.png`)
  },

  methods: {
    avatarElect(num) {
      this.userId = this.$store.state.userId;
      this.avatarNum = num;
      this.img = require(`./assets/profile/${num}.png`)
    },

    async submitAvatar() {
      const response = await this.$http.post('/api/user', {
        'userId' : Number.parseInt(this.userId),
        'avatarCode': this.avatarNum
      })
      if (response.data)
        this.$emit('elect-avatar', this.avatarNum)
      this.$emit('closeAvatar')
      this.$store.commit('setUserAvatar', this.avatarNum)
    },

    cancelAvatar() {
      this.avatarDialogVisible = false
      this.$emit('closeAvatar')
      this.img = require(`./assets/profile/${this.$store.state.avatarCode}.png`)
    }
  }

}
</script>

<template>
  <div>
    <div>
      <div style="display: flex; justify-content: center; margin-bottom: 20px">
        <img style="width: 120px; height: 120px" :src="img" alt=""/>
      </div>
      <div style="display: flex">
        <div style="display: flex; flex-wrap: wrap">
          <div class="avatarChoose" @click="avatarElect(1)">
            <img src="./assets/profile/1.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(2)">
            <img src="./assets/profile/2.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(3)">
            <img src="./assets/profile/3.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(4)">
            <img src="./assets/profile/4.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(5)">
            <img src="./assets/profile/5.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(6)">
            <img src="./assets/profile/6.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(7)">
            <img src="./assets/profile/7.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(8)">
            <img src="./assets/profile/8.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(9)">
            <img src="./assets/profile/9.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(10)">
            <img src="./assets/profile/10.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(11)">
            <img src="./assets/profile/11.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(12)">
            <img src="./assets/profile/12.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(13)">
            <img src="./assets/profile/13.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(14)">
            <img src="./assets/profile/14.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(15)">
            <img src="./assets/profile/15.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(16)">
            <img src="./assets/profile/16.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(17)">
            <img src="./assets/profile/17.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(18)">
            <img src="./assets/profile/18.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(19)">
            <img src="./assets/profile/19.png" alt="" style="width: 100%;height: 100%"/>
          </div>
          <div class="avatarChoose" @click="avatarElect(20)">
            <img src="./assets/profile/20.png" alt="" style="width: 100%;height: 100%"/>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; justify-content: center; margin-top: 25px">
      <el-button @click="cancelAvatar">cancel</el-button>
      <el-button type="primary" @click="submitAvatar">confirm</el-button>
    </div>
  </div>
</template>

<style scoped lang="less">

.avatarChoose {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin-left: 8px;
  margin-bottom: 5px;

}


</style>