<template>
  <div class="emoji">
    <ul class="emoji-default" v-show="isShowEmoji">
      <li v-for="(item, index) in emojiJson" :key="index"
          @click.stop="chooseEmojiDefault(item)">
        {{item}}
      </li>
    </ul>
    <div class="emoji-tabs">
      <div :class="!isShowEmoji ? 'emoji-tab' : 'new_tab'" @click="isShowEmoji = !isShowEmoji">
        {{ isShowEmoji ? '收起' : emojiJson[4]}}
      </div>
    </div>
  </div>
</template>

<script>

const emojiJson = require("./emoji.json");

export default {
  props: {
    // keyId: {
    //   type: String,
    //   required: false
    // },
    layer: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      emojiJson: emojiJson.data.split(','),
      isShowEmoji: false,
    }
  },
  methods: {
    chooseEmojiDefault(item) {
      // this.$emit("chooseEmoji",this.keyId,this.layer,item);
      this.$emit("chooseEmoji",this.layer, item);
      // this.$emit("chooseEmoji",item);
      this.isShowEmoji = false;
    }
  }
}

</script>

<style lang="scss" scoped>
// 纵向滚动条
@mixin scroll-bar($width: 10px) {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar {
    width: $width;
    height: 10px;
    background-color: #ffffff;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

.emoji {
  text-align: left;
  // width: 100%;
  // height: 100%;
  background: #fff;
  // border: 1px solid #dcdfe6;
  //box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%), 0 0 6px 0 rgb(0 0 0 / 4%);

  .emoji-tabs {
    // border-top: 1px solid #DCDFE6;
    background: #f8f8f8;

    .emoji-tab {
      cursor: pointer;
      //background: #ffffff;
      ///* height: 100%; */
      /* padding: 5px 5px; */
      /* overflow: hidden; */
      text-align: center;
      /* line-height: 15px; */
      font-size: 25px;
      border: none;
      background-color: #1a2234;
    }
    .new_tab {
      cursor: pointer;
      font-size: 14px;
      color: #1c33e5;
      text-align: center;
      padding: 5px 0;
    }
  }


  .emoji-default {
    width: calc(100% - 40px);
    height: 202px;
    overflow-y: auto;
    @include scroll-bar();
    padding: 0px 20px;

    li {
      display: inline-block;
      padding: 5px;
      font-size: 24px;
      width: 29px;
      height: 29px;
      overflow: hidden;
      cursor: pointer;
    }

    li:hover {
      background-color: #d5d5d5;
    }
  }
}

</style>